@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, Roboto, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dialog-fabricante>div[class*="MuiDialog-container-"]>div[class*="MuiPaper-root"] {
  width: 50%
}

.dialog-fabricado>div[class*="MuiDialog-container-"]>div[class*="MuiPaper-root"] {
  width: 70%
}

.header-fob {
  display: none !important;
  pointer-events: none !important;
}

.cell-fob {
  display: none !important;
  pointer-events: none !important;
}

.dialog-caracteristica>div[class*="MuiDialog-container-"]>div[class*="MuiPaper-root"] {
  width: 70%
}

.dialog-caracteristica-mobile>div[class*="MuiDialog-container-"]>div[class*="MuiPaper-root"] {
  width: 100%;
  height: 100%
}

@media screen and (max-width: 940px) {
  .snackCustom>div>div:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}